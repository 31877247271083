import React, { Suspense } from "react";
import ReactMarkdown from "react-markdown";
import Block from "../../adapters/helpers/Block";
import PropTypes from "prop-types";
import htmlParser from "react-markdown/plugins/html-parser";
import { MarkdownTextConstants } from "../../adapters/helpers/Constants";
import Loader from "../../adapters/helpers/Loader";

const astPlugins = [
  htmlParser({
    isValidNode: (node) => node.type !== MarkdownTextConstants.script,
    processingInstructions: [
      /* ... */
    ],
  }),
];

export function Markdown({ source, className }) {
  return (
    <ReactMarkdown
      source={source}
      className={className}
      escapeHtml={false}
      astPlugins={astPlugins}
    />
  );
}

Markdown.propTypes = {
  source: PropTypes.any,
  className: PropTypes.string,
};

export default function MarkdownText(props) {
  const block = new Block(props);
  const document = block.getDocument();
  const classNames = document?.fields?.classNames;
  let ariaHidden = null;
  if (classNames && classNames.indexOf("aria-hidden") >= 0) {
    ariaHidden = true;
  }
  const Tag = props.isSpan ? "span" : "div";

  return document?.fields ? (
    <Tag className={classNames} aria-hidden={ariaHidden}>
      <Suspense fallback={<Loader />}>
        <ReactMarkdown
          source={block.getFieldValue(MarkdownTextConstants.text)}
          escapeHtml={false}
          astPlugins={astPlugins}
        />
      </Suspense>
    </Tag>
  ) : (
    <Tag className={props.className} aria-hidden={ariaHidden}>
      <Suspense fallback={<Loader />}>
        <ReactMarkdown
          source={props.value}
          escapeHtml={false}
          astPlugins={astPlugins}
        />
      </Suspense>
    </Tag>
  );
}

MarkdownText.propTypes = {
  value: PropTypes.string,
  isSpan: PropTypes.bool,
  className: PropTypes.string,
};
